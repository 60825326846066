@if (active) {
	<div class="mt-2" [formGroup]="form">
		<div class="row mt-3 pt-2 border-top">
			<div class="col-9 d-flex flex-column">
				<div>
					<label class="form-label form-label-required" translate="AllianzTrade.ApiKey"></label>
					<input class="form-control col-6" [class.is-invalid]="apiKeyField.dirty && apiKeyField.invalid" formControlName="apiKey" [placeholder]="'AllianzTrade.ApiKey' | translate" />
					<form-control-feedback [field]="apiKeyField"/>
				</div>
				<allianz-trade-option-policy-line-form formControlName="policies" [currencies]="currencies"/>
				<div class="mt-3">
					@if (form.disabled) {
						<button class="btn btn-link" translate="Actions.Edit" data-cy="Edit" (click)="form.enable()"></button>
					}
					@else {
						<button class="btn btn-link" [disabled]="form.invalid" translate="Actions.Validate" data-cy="Validate" (click)="validate()"></button>
					}
				</div>
			</div>
		</div>
	</div>
}

