import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { FormControlFeedbackComponent, ICurrencyListItemTranslated, UnsubscriberClass } from '@aston/foundation';
import { TranslateModule } from '@ngx-translate/core';

import { IAllianzTradeOption, IAllianzTradePolicy } from '../../models';
import { AllianzTradeOptionPolicyLineFormComponent } from '../allianz-trade-option-policy-line-form/allianz-trade-option-policy-line-form.component';

@Component({
	selector: 'allianz-trade-option',
	templateUrl: './allianz-trade-option.component.html',
	standalone: true,
	imports: [ReactiveFormsModule, TranslateModule, FormControlFeedbackComponent, AllianzTradeOptionPolicyLineFormComponent]
})
export class AllianzTradeOptionComponent extends UnsubscriberClass implements OnChanges {
	@Input() active = false;
	@Input() values: IAllianzTradeOption = { isEnabled: false, apiKey: "", policies: [] };
	@Input() currencies: ICurrencyListItemTranslated[] = [];

	@Output() changeForm = new EventEmitter<FormGroup>();
	@Output() submitForm = new EventEmitter<IAllianzTradeOption>();

	form: FormGroup = this.formBuilder.group({
		isEnabled: new FormControl<boolean>(false),
		apiKey: new FormControl<string>('', [Validators.required]),
		policies: new FormControl<IAllianzTradePolicy[]>([], [this.allPoliciesFieldsFilled.bind(this)]),
	})

	get apiKeyField() {
		return this.form.get('apiKey') as AbstractControl;
	}

	get policiesField() {
		return this.form.get('policies') as AbstractControl;
	}

	constructor(private formBuilder: FormBuilder) {
		super()
	}

	ngOnChanges(changes: SimpleChanges): void {
		const justActivated = changes["active"]
			&& !changes["active"].previousValue
			&& !changes["active"].firstChange
			&& changes["active"].currentValue;

		const policies = !this.values?.policies?.length
			? [{policyId: "", policyProduct: "", lastUpdateDate: new Date()}]
			: this.values.policies;

		this.form.patchValue({
			...this.values,
			policies,
		});

		if (!justActivated || changes["active"]?.firstChange) {
			this.form.disable();
		}
		else if (this.active) this.form.enable();

		this.form.valueChanges
			.pipe(takeUntil(this.destroySubscriptions$))
			.subscribe(_ => this.changeForm.next(this.form));
		// initial emission for the parent to update the Validate button
		this.changeForm.next(this.form)
	}

	allPoliciesFieldsFilled(control: AbstractControl): ValidationErrors | null {
		const policies: IAllianzTradePolicy[] = control.value;
		return policies.find(p => this.policyValidator(p)) || null;
	}

	policyValidator(p: IAllianzTradePolicy): ValidationErrors | null {
		if (!p.policyId || !p.policyProduct) return { required: true };
		// User Story 32677: [ACC][Limite de Crédit Allianz Trade] Créer l'option de récupération des données assureur Allianz Trade
		if (p.policyUnnamedAmount && !p.policyUnnamedCurrencyCode) return { required: true };
		if (!p.policyUnnamedAmount && p.policyUnnamedCurrencyCode) return { required: true };
		return null
	}

	validate() {
		this.submitForm.emit(this.form.value)
		this.form.disable()
	}
}
