import { Observable } from "rxjs";
import { InjectionToken } from "@angular/core";

export interface IAllianzTradeAppConnector {
    apiUrl: Observable<string>;
}

export const ALLIANZ_CONNECTOR = new InjectionToken<IAllianzTradeAppConnector>(
    'Please provide AllianzTradeAppConnector for @aston/allianz-trade'
);
