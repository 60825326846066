
import { Component, Input, OnInit, computed, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AmountPipe, FormControlFeedbackComponent, LoaderSpinnerComponent, SanitizeHtmlPipe, SignalLoader } from '@aston/foundation';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AllianzTradeStore } from '../../store';

@Component({
	selector: 'allianz-trade-details',
	templateUrl: './allianz-trade-details.component.html',
	standalone: true,
	imports: [AsyncPipe, AmountPipe, SanitizeHtmlPipe, ReactiveFormsModule, TranslateModule, LoaderSpinnerComponent, FormControlFeedbackComponent]
})
export class AllianzTradeDetailsComponent implements OnInit {
	private store = inject(AllianzTradeStore);

	@Input() superDebtorId = 0;
	@Input() readonly = true;

	form: UntypedFormGroup = new FormGroup({});

	editing = false;

	get providerIdentifierField() {
		return this.form.get('providerIdentifier') as FormControl;
	}

	get coverIdField() {
		return this.form.get('coverId') as FormControl;
	}

	data = computed(() => {
		const data = this.store.entity()
		this.form?.patchValue(data)
		return data
	})

	loader = new SignalLoader(this.store.loading, this.store.error);

	constructor(
		private formBuilder: UntypedFormBuilder
	) {}

	ngOnInit() {
		this.buildForm();
		this.store.load(this.superDebtorId);
	}

	buildForm() {
		this.form = this.formBuilder.group({
			providerIdentifier: new UntypedFormControl(null),
			coverId: new UntypedFormControl(null, Validators.required),
			providerLimit: new UntypedFormControl(),
			providerLimitType: new UntypedFormControl(),
			providerLimitCurrencyCode: new UntypedFormControl(),
			restrictiveDecisionReason: new UntypedFormControl(),
		})
	}

	edit() {
		this.editing = true;
	}

	validate() {
		this.editing = false;
		if (this.providerIdentifierField.value === '') this.providerIdentifierField.setValue(null);
		this.store.update(this.superDebtorId, this.form.value);
	}

	refresh() {
		this.editing = false;
		this.store.refresh(this.superDebtorId);
	}
}
