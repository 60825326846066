import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { inject } from '@angular/core';
import { callStated, withCallState } from '@aston/foundation';

import { AllianzTradeService } from '../services/allianz-trade.service';
import { IAllianzTradeCompanyData } from '../models';

export const AllianzTradeStore = signalStore(
	{ providedIn: 'root' },
	withState({
		entity: undefined as unknown as IAllianzTradeCompanyData
	}),
	withCallState(),
	withMethods(({ ...store }) => {
		const service = inject(AllianzTradeService);
		return {
			load: (id: number) => {
				patchState(store, { entity: undefined })
				return callStated(service.get(id), store)
			},
			update: (id: number, data: IAllianzTradeCompanyData) => callStated(service.update(id, data), store),
			refresh: (id: number) => callStated(service.refresh(id), store),
		};
	}),
);
