import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ALLIANZ_CONNECTOR, IAllianzTradeAppConnector } from '../../di';
import { IAllianzTradeCompanyData } from '../models';

@Injectable({
	providedIn: 'root'
})
export class AllianzTradeService {

	constructor(
		private http: HttpClient,
		@Inject(ALLIANZ_CONNECTOR) private app: IAllianzTradeAppConnector,
	) { }

	public get(id: number): Observable<IAllianzTradeCompanyData> {
		return this.app.apiUrl.pipe(
			switchMap(api => this.http.get<IAllianzTradeCompanyData>(`${api}/api/AllianzTrade/superdebtor/${id}/company`))
		)
	}

	public update(id: number, data: IAllianzTradeCompanyData): Observable<IAllianzTradeCompanyData> {
		return this.app.apiUrl.pipe(
			switchMap(api => this.http.post<IAllianzTradeCompanyData>(`${api}/api/AllianzTrade/superdebtor/${id}/company`, data))
		)
	}

	public refresh(id: number): Observable<IAllianzTradeCompanyData> {
		return this.app.apiUrl.pipe(
			switchMap(api => this.http.post<IAllianzTradeCompanyData>(`${api}/api/AllianzTrade/superdebtor/${id}/refresh`, {}))
		)
	}
}
