import { Component, EventEmitter, Input, OnChanges, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, UntypedFormBuilder, ValidationErrors, Validator, Validators } from '@angular/forms';
import { CurrencyCodeType, CustomValidators, DateFormatPipe, FormControlFeedbackComponent, ICurrencyListItemTranslated } from '@aston/foundation';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

import { IAllianzTradePolicy } from '../../models';

@Component({
	selector: 'allianz-trade-option-policy-line-form',
	templateUrl: './allianz-trade-option-policy-line-form.component.html',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule, TranslateModule, DateFormatPipe, FormControlFeedbackComponent],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AllianzTradeOptionPolicyLineFormComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: AllianzTradeOptionPolicyLineFormComponent,
			multi: true,
		},
	]
})
export class AllianzTradeOptionPolicyLineFormComponent implements ControlValueAccessor, Validator, OnChanges, OnInit {
	@Input() currencies: ICurrencyListItemTranslated[] = [];
	@Output() valueChange = new EventEmitter();

	control: FormArray<FormGroup> = this.fb.array([]);

	policyIdField(index: number) { return this.control?.get(`${index}.policyId`) }
	extensionIdField(index: number) { return this.control?.get(`${index}.extensionId`) }
	policyProductField(index: number) { return this.control?.get(`${index}.policyProduct`) }
	lastUpdateDateField(index: number) { return this.control?.get(`${index}.lastUpdateDate`) }
	policyUnnamedAmountField(index: number) { return this.control?.get(`${index}.policyUnnamedAmount`) }
	policyUnnamedCurrencyCodeField(index: number) { return this.control?.get(`${index}.policyUnnamedCurrencyCode`) }

	constructor(private fb: UntypedFormBuilder) { }

	validate(_: FormControl): ValidationErrors | null {
		return this.control.valid ? null : { required: true };
	}

	registerOnValidatorChange(fn: CallableFunction): void {
		this.valueChange.subscribe(fn);
	}

	ngOnChanges() {
		if (this.control) this.notifyChange();
	}

	ngOnInit() {
		this.control = this.fb.array([]);
		this.control.valueChanges.subscribe(_ => this.notifyChange());
	}

	notifyChange() {
		this.valueChange.emit(
			this.control.value.map((l: IAllianzTradePolicy) => ({
				policyId: l.policyId,
				extensionId: l.extensionId,
				policyProduct: l.policyProduct,
				lastUpdateDate: l.lastUpdateDate,
				policyUnnamedAmount: l.policyUnnamedAmount,
				policyUnnamedCurrencyCode: l.policyUnnamedCurrencyCode,
			})
		));
	}

	newRow(policyId = '', policyProduct = '', extensionId = '', lastUpdateDate?: Date, amount?: number, currencyCode?: CurrencyCodeType): FormGroup {
		return this.fb.group({
			policyId: [policyId, [Validators.required]],
			extensionId: [extensionId, [Validators.maxLength(5), CustomValidators.integer]],
			policyProduct: [policyProduct, [Validators.required]],
			lastUpdateDate: [lastUpdateDate || new Date],
			policyUnnamedAmount: [amount, [CustomValidators.integer]],
			policyUnnamedCurrencyCode: [currencyCode],
		});
	}

	deleteRow(index: number) {
		this.control.removeAt(index);
	}

	addRow() {
		this.control.insert(this.control.controls.length, this.newRow());
	}

	writeValue(lines: IAllianzTradePolicy[]): void {
		while(this.control.controls.length > 0) this.control.removeAt(0);
		lines?.forEach((l, i) => {
			this.control.insert(i,
				this.newRow(l.policyId, l.policyProduct, l.extensionId, l.lastUpdateDate, l.policyUnnamedAmount, l.policyUnnamedCurrencyCode));
		})
	}

	registerOnChange(fn: CallableFunction): void {
		this.valueChange.subscribe(fn);
	}

	registerOnTouched(): void {
		//
	}

	setDisabledState(isDisabled: boolean): void {
		if (isDisabled)	this.control.disable();
		else this.control.enable();
	}
}
