@for (group of control.controls; let i = $index; track group) {
	<div class="mt-2" [formGroup]="group">
		<label class="form-label form-label-required">{{ "AllianzTrade.Policy" | translate: {number: i + 1} }}</label>
		<div class="d-flex align-items-top">
			<div class="col-2 pl-0">
				<input class="form-control col-12"
					formControlName="policyId"
					[class.is-invalid]="policyIdField(i)?.dirty && policyIdField(i)?.invalid"
					[placeholder]="'AllianzTrade.Policy' | translate: { number: '00000000' }" />
				<form-control-feedback [field]="policyIdField(i)"/>
			</div>
			<div class="col-1 pl-0">
				<input class="form-control col-12"
					formControlName="extensionId"
					[class.is-invalid]="extensionIdField(i)?.dirty && extensionIdField(i)?.invalid"
					[placeholder]="'AllianzTrade.Extension' | translate" />
				<form-control-feedback [field]="extensionIdField(i)"/>
			</div>
			<div class="col-2 pl-0">
				<input class="form-control col-12"
					formControlName="policyProduct"
					[class.is-invalid]="policyProductField(i)?.dirty && policyProductField(i)?.invalid"
					[placeholder]="'AllianzTrade.BusinessUnit' | translate" />
				<form-control-feedback [field]="policyProductField(i)"/>
			</div>
			<div class="col-2 pl-0">
				<input class="form-control col-12" type="number"
					formControlName="policyUnnamedAmount"
					[class.is-invalid]="policyUnnamedAmountField(i)?.dirty && policyUnnamedAmountField(i)?.invalid"
					[placeholder]="'AllianzTrade.Amount' | translate" />
				<form-control-feedback [field]="policyUnnamedAmountField(i)"/>
			</div>
			<div class="col-2 pl-0">
				<select
					class="form-control form-control-alt"
					formControlName="policyUnnamedCurrencyCode">
						<option [ngValue]="null">{{ 'AllianzTrade.NoCurrency' | translate }}</option>
						<option hidden value="">{{ 'AllianzTrade.Currency' | translate }}</option>
						@for (currency of currencies; track currency) {
							<option [ngValue]="currency.currencyCode">{{ currency.currencyName }}</option>
						}
				</select>
				<form-control-feedback [field]="policyUnnamedCurrencyCodeField(i)"/>
			</div>
			<div class="col-2 pl-0">
				<div class="form-control pt-0 border-0 card-item-date">
					{{ "AllianzTrade.LastUpdateDate" | translate }} : {{ lastUpdateDateField(i)?.value | dateFormat: 'WithHours2' }}
				</div>
			</div>
			<div class="col-1 pl-0">
				@if (!group.disabled) {
					<div class="form-control border-0 d-flex">
						<i class="fas fa-plus-circle cursor-pointer ml-4 mr-2" (click)="addRow()"></i>
						@if (i !== 0) { <i class="fas fa-minus-circle cursor-pointer ml-4 mr-4" (click)="deleteRow(i)"></i> }
					</div>
				}
			</div>
		</div>
	</div>
}

