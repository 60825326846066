<section class="content-block has-padding super-debtor-detail-credit-insurance" [class.block-loader]="loader.isLoading$ | async" [class.with-data]="!!data()">
	<loader-spinner [model]="loader"/>
	<div class="content-block-main">
		<header class="content-block-header">
			<h2 translate="SuperDebtors.Tabs.CreditInsurance"></h2>
			<div>
				@if (editing) {
					<button class="btn btn-primary ml-3" translate="Actions.Validate" data-cy="Validate" [disabled]="readonly || form.invalid" (click)="validate()"></button>
				}
				@else {
					<button class="btn btn-primary ml-3" translate="Actions.Edit" data-cy="Edit" [disabled]="readonly" (click)="edit()"></button>
				}
				<button class="btn btn-primary ml-3" translate="Actions.Refresh" data-cy="Refresh" (click)="refresh()" [disabled]="editing"></button>
			</div>
		</header>

		<form class="col-12 allianz-trade-details-list" [formGroup]="form">
			<ul class="infos-list">

				<li class="half-width">
					<span class="label" translate="AllianzTrade.ProviderIdentifier"></span>
					<span>
						@if (editing) {
							<input type="text" formControlName="providerIdentifier" class="form-control w-100"
								[class.is-invalid]="providerIdentifierField.dirty && providerIdentifierField.invalid" />
						}
						@else {
							<div>{{ providerIdentifierField.value || '-' }}</div>
						}
					</span>
				</li>

				<li class="half-width">
					<span class="label" translate="AllianzTrade.CoverId"></span>
					<span>
						@if (editing) {
							<input type="text" formControlName="coverId" class="form-control w-100"
								[class.is-invalid]="coverIdField.dirty && coverIdField.invalid" />
						}
						@else {
							<div>{{ coverIdField.value || '-' }}</div>
						}
						<form-control-feedback [field]="coverIdField"/>
					</span>
				</li>

				<li class="half-width">
					<span class="label" translate="AllianzTrade.ProviderLimit"></span>
					<span><div>{{ form.value.providerLimit | amount: { with:'symbol-narrow', currency: form.value.providerLimitCurrencyCode } }}</div></span>
				</li>

				<li class="half-width">
					<span class="label" translate="AllianzTrade.ProviderLimitType"></span>
					<span><div>{{ form.value.providerLimitType || '-' }}</div></span>
				</li>

				<li class="half-width">
					<span class="label" translate="AllianzTrade.Comment"></span>
					<span>
						@if (form.value.restrictiveDecisionReason) {
							<div [innerHTML]="form.value.restrictiveDecisionReason | sanitizeHtml"></div>
						}
						@else {
							<div translate="AllianzTrade.NoComment"></div>
						}
					</span>
				</li>
			</ul>
		</form>

	</div>
</section>
