import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbAlertModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AmountPipe, DateFormatPipe, FormControlFeedbackComponent, LoaderSpinnerComponent, SanitizeHtmlPipe } from '@aston/foundation';

import { AllianzTradeDetailsComponent, AllianzTradeOptionComponent, AllianzTradeOptionPolicyLineFormComponent } from './components';

@NgModule({
	exports: [
		AllianzTradeDetailsComponent,
		AllianzTradeOptionComponent,
	],
	imports: [
		AllianzTradeDetailsComponent,
		AllianzTradeOptionComponent,
		AllianzTradeOptionPolicyLineFormComponent,
		AmountPipe,
		CommonModule,
		DateFormatPipe,
		FormControlFeedbackComponent,
		FormsModule,
		LoaderSpinnerComponent,
		NgbAlertModule,
		NgbTooltipModule,
		NgSelectModule,
		ReactiveFormsModule,
		RouterModule,
		SanitizeHtmlPipe,
		TranslateModule,
	],
})
export class AllianzTradeModule {}
